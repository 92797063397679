<template>
  <div>
    <div class="auth-page__header">
      <div class="auth-page__title">{{ $t("approveShPu") }}</div>
    </div>
    <div class="auth-confirm__subtitle">{{ $t("smsApproveTitle") }}</div>
    <div class="auth-confirm__otp">
      <otp-input
        ref="otpInput"
        input-classes="otp-input"
        separator=""
        :num-inputs="4"
        :should-auto-focus="true"
        :is-input-num="true"
        @on-change="handleOnChange"
        @on-complete="handleOnComplete"
      />
    </div>
    <div class="auth-confirm__repeat">
      <div v-if="codeConfirmTimerValue" class="auth-confirm__repeat-wait">
        {{ $t("codeConfirmTime") }}:
        <span>{{ codeConfirmTimerValue }} {{ $t("sek") }}</span>
      </div>
      <div v-else @click="sendRepeat" class="auth-confirm__repeat-send">
        <span>{{ $t("getSMSReg") }}</span>
      </div>
    </div>
    <button
      :class="{ isLoading: isLoading }"
      :disabled="isNotValid"
      class="btnWideRound"
      @click="onSubmit"
    >
      {{ $t("btnNext") }}
    </button>
  </div>
</template>

<script>
import OtpInput from "@bachdgvn/vue-otp-input";
import { mapActions } from "vuex";
import eventsRegister from "@/services/events-register";
import { logEventFA, logEventFB } from "@/utils/utils";

export default {
  name: "SignUpConfirm",
  components: { OtpInput },
  data() {
    return {
      confirmCode: "",
      isSubmitted: false,
      isLoading: false,
      codeConfirmTimer: null,
      codeConfirmTimerValue: 30,
    };
  },
  created() {
    this.setTimer();
  },
  computed: {
    isNotValid() {
      return this.confirmCode.length < 4;
    },
  },
  methods: {
    ...mapActions("user", ["repeatSignUp", "confirmSignUp"]),
    async handleOnComplete() {
      await this.onSubmit();
    },
    handleOnChange(value) {
      this.confirmCode = value;
    },
    setTimer() {
      this.codeConfirmTimerValue = 30;
      this.codeConfirmTimer = setInterval(() => {
        if (this.codeConfirmTimerValue > 0) this.codeConfirmTimerValue--;
        else clearInterval(this.codeConfirmTimer);
      }, 1000);
    },
    async sendRepeat() {
      try {
        await this.repeatSignUp();
        this.setTimer();
      } catch (error) {
        this.$root.$emit(eventsRegister.addMessage, {
          type: "error",
          message: this.$t("serverError"),
        });
      }
    },
    async onSubmit() {
      if (this.isLoading) return false;

      if (!this.isNotValid) {
        this.isLoading = true;
        try {
          await this.confirmSignUp({
            confirmCode: this.confirmCode,
          });
          logEventFA("sign_up", { method: "phone_number" });
          logEventFB("EVENT_NAME_COMPLETED_REGISTRATION", {
            REGISTRATION_METHOD: "phone_number",
          });
          this.$router.push({ name: "Root" });
          this.isLoading = false;
        } catch (error) {
          const message = error.Message
            ? this.$t("confirmCodeErr")
            : this.$t("serverError");
          this.$root.$emit(eventsRegister.addMessage, {
            type: "error",
            message,
          });
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style></style>
